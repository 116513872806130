import React, { useEffect } from 'react';

import AOS from 'aos';
import Sticky from 'sticky-js';
import { focusHandling } from 'cruip-js-toolkit';

// import Header from '../components/layout/Header';
// import Footer from '../components/layout/Footer';

const initiateRR = () => {
  var RR_APP_ID = "tpqxZq0ZmkJoOqG1olL9"; // YOUR TEST ENVIRONMENT"
  window.RetainRocket = null;
  // window.RR_APP_ID = "tpqxZq0ZmkJoOqG1olL9";

  (function(r,aa,f,t,dot,i,o) {
      r[dot] =
          r[dot] ||
          function() {
              (r[dot].q = r[dot].q || []).push(arguments);
          };
      r[dot].l = 1 * new Date();
      i = aa.createElement(f);
      o = aa.getElementsByTagName(f)[0];
      i.id = 'RetainRocketScript';      i.async = 1;
      i.src = t;
      o.parentNode.insertBefore(i, o);
  })(window, document, 'script', 'https://widget.retainrocket.com/widget.js?appId=' + RR_APP_ID, 'rr');
}

const LayoutDefault = ({ children, location }) => {
    // initiateRR();

    useEffect(() => {
      AOS.init({
        once: true,
        disable: 'phone',
        duration: 700,
        easing: 'ease-out-cubic',
      });
      // eslint-disable-next-line no-unused-vars
      const sticky = new Sticky('[data-sticky]');
    });
  
    useEffect(() => {
      document.querySelector('html').style.scrollBehavior = 'auto'
      window.scroll({ top: 0 })
      document.querySelector('html').style.scrollBehavior = ''
      focusHandling('outline');
    }, [location.pathname]); // triggered on route change

    return <>
        {children}
        <div id="RetainRocketScript"></div>
    </>
};

export default LayoutDefault;
import React from 'react';
import Accordion from '../utils/Accordion';

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2 text-gray-700">Questions & Answers</h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            <Accordion title="Do I need coding knowledge to use this product?">
                Not really. All you have to do is paste the widget snippet inside of your application, exactly where you'd like to see your "Unsubscribe" Button.
            </Accordion>
            <Accordion title="How do you help me lower my churn?">
                There are two ways we can help you lower your churn: <br />
                - We provide your customers different options before they unsubscribe. If they thik the software is too expensive, we can offer them a coupon code or if they think that the software is too hard to understand, we offer them to schedule a call with the support team.<br />
                - We help you understand why exactly the customers are churning. Even if they decide that what our retention funnel offers them is not relevant, you (as a business) still get feedback from those who churn.  
            </Accordion>
            <Accordion title="Do you work with Stripe?">
                Absolutely, we are fully integrated with Stripe.
            </Accordion>
            <Accordion title="Do you work with Braintree, Recurly or Chargebee?">
              Not yet, but we are working on it.
            </Accordion>
            <Accordion title="Does RetainRocket take care of the cancellation instead of me?">
                Indeed, when your customer truly desires to cancel, RetainRocket processes the cancellation for you. Inside of the funnel setup, you can specify if you'd like the subscription end immideately or at the end of the billing period.            </Accordion>
            <Accordion title="What are Motivators?">
                Motivators help customers self-segment their primary reason for considering cancellation. When a customers selects their primary motivator for canceling, Raaft gives you the ability to automatically respond to that objection in an attempt to retain the customer. Evaluating motivator trends in the dashboard help you understand if your product modifications have affected retention. 
            </Accordion> 
            <Accordion title="What is a Retention Flow?">
            RetainRocket Retention Funnel replaces your current cancellation experience with a customized user experience aimed at understanding why your subscriber is canceling and providing options to retain them.
            </Accordion>
            <Accordion title="Can I get help setting up your widget?">
              Totally, we are more than happy to help you with setting up the widget.
            </Accordion>
            <Accordion title="Do you record when my customers want to cancel?">
            Yes! RetainRocket keeps track of every customer that clicks your cancel button - even if they don't complete the cancellation or accept an offer. Knowing that a customer has viewed the cancellation page can provide powerful insight into the state of the account.</Accordion>
            <Accordion title="How does RetainRocket calculate the amount of money saved?">
            Every customer that accepts a response rather than canceling is marked as "saved". All future subscription renewals are considered "saved revenue". This gives you simple insight into how much money you are saving with us.
            </Accordion> 
            <Accordion title="How can I upgrade my plan?">
              Select the desired subscription plan from the Pricing page and follow the steps to upgrade. Depending on your current plan and its prepaid period, you’ll only pay the prorated amount in the first payment for the new plan.
              </Accordion>
              <Accordion title="How can I pay you?">
              You can pay with your Visa, Maestro, MasterCard, American Express or Discover cards, use PayPal, send money via Bank Transfer or if you’re really cool, pay with Bitcoin 😉
              </Accordion>
              {/* <Accordion title="Can I pay monthly or yearly?">
                Yes, we have both annual and monthly plans. Don’t forget that you can save 2 months by subscribing to the annual plan when compared to the plan paid monthly!

                You can find the monthly options when you click on the “Monthly” button on the top part of our Pricing page once you create an account.
              </Accordion> */}
              <Accordion title="Do you provide any discounts?">
              There are special offers few times a year. Moreover, you might get a special time-limited early-bird yearly discount one hour after registration for our monthly plan.
              </Accordion>
              <Accordion title="Can I upgrade/downgrade/cancel my subscription?">
              Yes, you can change or cancel your plan at any time in your dashboard. Once you have canceled, you’ll still have access to your plan until the end of your subscription cycle.
              </Accordion>
              <Accordion title="Are there any contracts, hidden charges or setup fees?">
              No contracts, no hidden charges, no setup fees. There is no long-term commitment and you can cancel your subscription at any time.
              </Accordion>
              <Accordion title="What happens if I stop paying for RetainRocket?">
                When your customers click "Unsubscribe", they will see a basic "Confirm your unsubscription" modal window instead of a RetainRocket cancellation funnel.
              </Accordion>
              {/* <Accordion title="What is your refund policy?">
                We do not offer, you have 7 days after the transaction to request a refund, but note that we reserve the right to decline that request should we see high activity on your account within this time.
              </Accordion>    */}
            <span className="block border-t border-gray-200" aria-hidden="true"></span>
          </ul >

        </div >
      </div >
    </section >
  );
}

export default Faqs;
